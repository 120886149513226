import React,{useEffect} from "react";
import "./certificate.css";
import logo from "../../assets/images/gtm-1.png"
import sign from '../../assets/images/sign.png';
import html2pdf from "html2pdf.js";

export default function Certificate({data}) {

    useEffect(() => {
        generatePDF();
      }, []);

    const generatePDF = () => {
        const element = document.getElementById('certificate-container');
        const opt = {
          margin: 0.5,
          filename: 'certificate.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        };
        html2pdf().from(element).set(opt).save();
      };

  return (
    <div className="certificatediv" id="certificate-container">
      <Icon />


      <div className="content">
      <img className='logo' src={logo} alt=""/>
        <h1>CERTIFICATE OF COMPLETION</h1>
        <p style={{textAlign:"center"}}>This certifies that</p>
        <h2>{data.employeename}</h2>
        <p>is a student of Centurion University has successfully completed his Internship with Satyalok for Frontend Developer from 10/01/2023 to 10/01/2024.
        We found him sincere, hardworking, dedicated, & result-oriented. He worked well during his tenure.</p> 

        <p>We take this opportunity to thank him and wish him all the best for his future endeavours.</p>
      </div>

        <div className="byline">
            <img src={sign} alt="" />
            <h3>Signature By Director </h3>
        </div>

    </div>
  );
}

const Icon = () => (
  <svg
    width="99"
    height="139"
    viewBox="0 0 99 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H99V138.406L52.1955 118.324L0 138.406V0Z" fill="white" />
    <path
      d="M25.4912 83.2515C25.4912 79.4116 27.0222 75.7289 29.7474 73.0137C32.4727 70.2985 36.1689 68.7731 40.0229 68.7731C43.877 68.7731 47.5732 70.2985 50.2984 73.0137C53.0236 75.7289 54.5546 79.4116 54.5546 83.2515M40.0229 59.724C40.0229 55.8841 41.5539 52.2014 44.2791 49.4862C47.0044 46.7709 50.7006 45.2455 54.5546 45.2455C58.4087 45.2455 62.1049 46.7709 64.8301 49.4862C67.5553 52.2014 69.0863 55.8841 69.0863 59.724V83.2515"
      stroke="#0379FF"
      strokeWidth="10.6193"
    />
  </svg>
);
