import React from 'react'
import './loader.css';

export default function Loader() {
  return (
    <div className='loading'>
      <div></div>
    </div>
  )
}
