import React from 'react'
import './notfound.css';

const NotFound = () => {
  return (
    <div className='notfounddiv'>
      <h1>
        No Page Found Sorry....
      </h1>
    </div>
  );
}

export default NotFound;
